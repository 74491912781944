import React, { useState } from "react"
import view from "../modal.module.scss"

export default function ModalParagraph (props) {
  const { modalParaButtonText, modalParaContent,className } = props

  const [modalVisible, toggleModal] = useState(false)

  const closeModal = () => {
    toggleModal(false)
  }

  const openModel = () => {
    toggleModal(true)
  }

  return (
    <>{
      modalVisible ? (
        <div className={view["modal"]} onClick={closeModal}>
        <div className={view["modal-wrap"]} onClick={e => e.stopPropagation()}>
          <div className={view["modal-head"]}>
            <p className={view["modal-close"]} role="link" aria-label="Close Modal" style={{ marginLeft: "1rem", textTransform: "uppercase" }}>
              { modalParaButtonText }
            </p>
            <button tabIndex="0" className={view["modal-close"]} onClick={closeModal}
              style={{ cursor: "pointer" }}
            >
              &#10006;
            </button>
          </div>
          <div className={view["modal-cont"]} style={{minHeight: "160px"}} dangerouslySetInnerHTML={{ __html: modalParaContent}}>
          </div>
        </div>
      </div>
      ) : (<></>)
    }
     <div className={className} onClick={openModel}>
      {modalParaButtonText}
     </div>
    </>
  )
}

ModalParagraph.defaultProps = {
  visible: true,
  closeModal: () => { }
}