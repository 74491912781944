import React, { useState } from "react"
import view from "./dropdown.module.scss"

const DropdownOption = ({ type, placeholder, values, addFilter, label, sorter }) => {
  const [option, setOption] = useState(placeholder)

  const renderOptions = () => {
    const keys =  Object.keys(values)
    const sortedKeys = sorter && keys.sort(sorter) || keys
    return sortedKeys.map((key, idx) => (
      <li tabIndex={"0"} key={idx} className={view["sub-dropdown__item"]} onKeyPress={(e) => {if (e.key === "Enter") { e.preventDefault(); e.target.click();}}} onClick={() => {
        onSelected(key)
      }}>
        {`${key} (${values[key]})`}
      </li>
    ))
  }

  const onSelected = (value) => {
    if (value != placeholder) {
      if (type === 'new' && value === 'Used & Certified') {
        addFilter({ value: 'Certified', type: 'certified'})
      } else if (type === 'new' || type === 'certified') {
        addFilter({ value: "", type: 'certified' })
        addFilter({ value: "", type: 'new' })
      }
      addFilter({ value, type })
    } else {
      if (placeholder === 'NEW & USED') {
        addFilter({ value: "", type: 'certified' })
      }
      addFilter({ value: "", type })
    }
    setOption(value)
    document.activeElement.blur()
  }

  return (
    <ul className={view["dropdowns"]} style={{ padding: 0 }}>
      <li className={view["dropdown"]} style={{ width: "100%" }}>
        <a tabindex="0" className={view["dropdown__inner"]}>
          <p className={view["dropdown__name"]}>{label && label}</p>
          <p className={view["dropdown__value"]}>{option}</p>
        </a>
        <ul tabindex="0" className={view["sub-dropdown"]}>
          <li tabIndex={"0"} className={view["sub-dropdown__item"]} onKeyPress={(e) => {if (e.key === "Enter") { e.preventDefault(); e.target.click();}}}
            onClick={() => { onSelected(placeholder) }}>
            {placeholder}
          </li>
          {renderOptions()}
        </ul>
      </li>
    </ul>
  )
}

export default DropdownOption 
