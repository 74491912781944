import React, { Link } from 'react'
import Slider from "react-slick"
import PropTypes from 'prop-types'
import "slick-carousel/slick/slick.css"
import "./AlphaSlider.scss"

import leftArrow from "../../images/icon-arrow-left.svg"
import rightArrow from "../../images/icon-arrow-right.svg"
import darkLeftArrow from "../../images/icon-arrow-left-dark.svg"
import darkRightArrow from "../../images/icon-arrow-right-dark.svg"

class AlphaSlider extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentSlide: 1,
            totalSlides: 0,
            paused: false,
            timer: '',
            percent: 0,
            alphaslider: [],
            disclaimerToggle: false,
            mobileDisclaimerToggle: false
        }

        this.sliderButton = React.createRef()
        this.disclaimerButton = React.createRef()
        this.disclaimerButton2 = React.createRef()
        this.disclaimerBox = React.createRef()
        this.mobileDisclaimerBox = React.createRef()
        this.progressBar = React.createRef()
        this.slider = React.createRef()
        this.nav = React.createRef()
        this.startProgressBar = this.startProgressBar.bind(this)
        this.checkTime = this.checkTime.bind(this)
    }

    async componentDidMount() {
        await this.checkDates() // checks the expiration of slides before starting the slider

        this.startProgressBar() // starts progress bar
        this.setState({
            totalSlides: this.state.alphaslider.length // assigns total slides length after expirations are checked
        })
    }

    checkDates() {
        return new Promise((resolve, reject) => {
            const today = new Date(Date.now()).getTime()
            let currentSlides = []
            this.props.data.alphaslider.forEach(slide => {
                const expiration = slide.expiration != '' ? new Date(slide.expiration).getTime() : new Date('01/01/1900').getTime()
                const startDate = slide.startDate != '' ? new Date(slide.startDate).getTime() : new Date().getTime()

                if (startDate <= today && today < expiration) currentSlides.push(slide) // pushed slide into new array if today fits between expiration and start date
            })

            if (currentSlides.length === 1) currentSlides = currentSlides.concat(currentSlides) // have to add a second slide because the slider breaks with only 1 slide

            this.setState({
                alphaslider: currentSlides
            })

            resolve(currentSlides)
        })
    }

    /* Comments: execute parameter allows user to pass in the result of an evalutation to skip execution conditionally. Pass true if it should always execute where it is called*/
    toggleSliderButton(execute) {
        if(execute) {
            if (this.state.paused) {
                this.slider.slickPlay()
                this.setState({ paused: false })
                this.sliderButton.current.classList.remove('slider-control__btn_play')
            } else {
                this.slider.slickPause()
                this.setState({ paused: true })
                this.sliderButton.current.classList.add('slider-control__btn_play')
            }
        }
    }

    startProgressBar() {
         if(this.slider != null) {
            this.slider.slickPlay()
            this.sliderButton.current.classList = ('slider-control__btn' + this.setIconColor(this.props.data.slideControlColor != '' ? this.props.data.slideControlColor : this.props.theme.colors.accent1))

            this.setState({
                timer: setInterval(this.checkTime, 30),
                percent: 0,
                paused: false
            })
	}
    }

    resetProgressBar() {
        clearTimeout(this.state.timer)
        this.progressBar.current.style.width = '0%'
        this.startProgressBar()
    }

    checkTime() {
        if (!this.state.paused) {
            this.setState({
                percent: this.state.percent + (100 / (10000 / 30))
            })
            
            if (this.progressBar.current != null) {
                this.progressBar.current.style.width = this.state.percent + '%'
            }
        }

        if (this.state.percent >= 100) {
            this.slider.slickNext()
        }
    }

    /* Comments: execute parameter allows user to pass in the result of an evalutation to skip execution conditionally. Pass true if it should always execute */
    toggleDisclaimer(execute) {
        if(execute) {
            if (this.state.mobileDisclaimerToggle) {
                this.setState({ mobileDisclaimerToggle: false })
            
                this.mobileDisclaimerBox.current.classList.remove('active')
                this.disclaimerButton2.current.classList.remove('active')
                this.toggleSliderButton(execute)
            }
            if (this.state.disclaimerToggle) {
                this.setState({ disclaimerToggle: false })

                this.disclaimerBox.current.classList.remove('active')
                this.disclaimerButton.current.classList.remove('active')
                this.toggleSliderButton(execute)
            } 
            else {
                this.setState({ disclaimerToggle: true })
                this.disclaimerBox.current.classList.add('active')
                this.disclaimerButton.current.classList.add('active')
            }
        }
    }

    /* Comments: execute parameter allows user to pass in the result of an evalutation to skip execution conditionally. Pass true if it should always execute */
    toggleMobileDisclaimer(execute) {
        if(execute) {
            if (this.state.disclaimerToggle) {
                this.setState({ disclaimerToggle: false })
    
                this.disclaimerBox.current.classList.remove('active')
                this.disclaimerButton.current.classList.remove('active')
                this.toggleSliderButton(execute)
            }
            if (this.state.mobileDisclaimerToggle) {
                this.setState({ mobileDisclaimerToggle: false })
        
                this.mobileDisclaimerBox.current.classList.remove('active')
                this.disclaimerButton2.current.classList.remove('active')
                this.toggleSliderButton(execute)
            } 
            else {
                this.setState({ mobileDisclaimerToggle: true })
                this.mobileDisclaimerBox.current.classList.add('active')
                this.disclaimerButton2.current.classList.add('active')
            }
        }
     }

     hideAll() {
         console.log("firing")
        this.setState({ disclaimerToggle: false })
        this.setState({ mobileDisclaimerToggle: false })

        console.log(this.disclaimerButton2.current)

        if (this.disclaimerBox != null)
            this.disclaimerBox.current.classList.remove('active')
        if (this.disclaimerButton != null)
            this.disclaimerButton.current.classList.remove('active')
        if (this.mobileDisclaimerBox != null)
            this.mobileDisclaimerBox.current.classList.remove('active')
        if (this.disclaimerButton2 != null)
            this.disclaimerButton2.current.classList.remove('active')
     }

    buildImageSourceSet(imageList, easyImageLg, easyImageMd, easyImageSm) {
        let imageSourceSetBase = {}
        let buildSrcSet = ''
        if(imageList.length > 0 && imageList[0].imageSource != '') {
            imageList.sort(function (a, b) {
                return a.imageBreakpoint-b.imageBreakpoint
            })
            
            imageSourceSetBase = {
                srcSetString: imageList.map((img, i) => {
                    let imageSource = img.imageSource
                    let imageBreakpoint = img.imageBreakpoint

                    return ({source: imageSource, breakpoint: imageBreakpoint})
                }),
                defaultImage: imageList[imageList.length - 1].imageSource

            }

            return imageSourceSetBase;
        }


        else {
           
            let easyImageList = []

            if (easyImageSm != '')
                easyImageList.push({source: easyImageSm, breakpoint: "700"})
            if (easyImageMd != '')
                easyImageList.push({source: easyImageMd, breakpoint: "1200"})
            if (easyImageLg != '')
                easyImageList.push({source: easyImageLg, breakpoint: "1500"})

            let defaultImageSource = easyImageLg

            imageSourceSetBase = {
                defaultImage: defaultImageSource,
                srcSetString: easyImageList
            }
            return imageSourceSetBase
        }
    }

    rgbaTranslator = (hexCode, opacityValue) => {
        const rVal = parseInt(hexCode.substr(1,2), 16).toString()
        const gVal = parseInt(hexCode.substr(3,2), 16).toString()
        const bVal = parseInt(hexCode.substr(5,2), 16).toString()
    
        return "rgba(" + rVal +  ", " + gVal + ", " + bVal + ", " + opacityValue + ")"
      }

    setIconColor(hexCode) {
        const rVal = parseInt(hexCode.substr(1,2), 16).toString()
        const gVal = parseInt(hexCode.substr(3,2), 16).toString()
        const bVal = parseInt(hexCode.substr(5,2), 16).toString()
        let counter = 0

        if(rVal > 128) { counter++}
        if(gVal > 128) { counter++}
        if(bVal > 128) { counter++}

        return counter >= 2 ? " black" : " white"

    }


    render() {
        if (this.state.alphaslider.length < 1) return <div style={{minHeight: '100vh', minWidth: '100vh'}}></div>

        const mainSettings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 10000,
            asNavFor: this.nav,
            afterChange: (index) => {
                this.resetProgressBar()

                this.setState({
                    currentSlide: index + 1
                });
                this.hideAll();
            }
        }

        const navSettings = {
            infinite: true,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            asNavFor: this.slider,
            variableWidth: false,
            centerMode: false,
            arrows: false
        }

        let slides = this.state.alphaslider.map((slide, i) => {

            let imageSrcSet = this.buildImageSourceSet(slide.imagesList,  slide.imageLink, slide.imageMedium, slide.imageSmall)

            let defaultImageSource = ''
             if(imageSrcSet.defaultImage != '') {
                defaultImageSource = imageSrcSet.defaultImage
            }

            return (
                <a draggable="false" href={(slide.link != '' ? slide.link : '')}>
                <div style={{ height: "100%" }} aria-label={slide.label} key={i}>
                    <div className="alpha slide-wrapper">
                        <div className="slide-picture-content">
                            <picture>
                                { 
                                    imageSrcSet.srcSetString.map((src, i) => {
                                        return (<source srcset={src.source} media={"(max-width: " + src.breakpoint +"px)"} /> )
                                    })
                                }
                                <img className="slide-picture" src={defaultImageSource} 
                                alt={slide.alt} title={slide.title} />
                            </picture>
                        </div>
                    </div>
                </div>
                </a>
            )
        })

        let navSlides = this.state.alphaslider.map((slide, i) => {
           // console.log(slide)

            let imageSrcSet = this.buildImageSourceSet(slide.imagesList, slide.imageLink, slide.imageMedium, slide.imageSmall)

            let defaultImageSource = ''
            if(imageSrcSet.defaultImage != '') {
                defaultImageSource = imageSrcSet.defaultImage
            }
            
            return (
                <div key={i} style={{ width: 150 }}>
                    <div className="imageWindow">
                        <img className="navImage" src={defaultImageSource} alt={slide.alt} />
                    </div>
                </div>
            )
        })

        let navArrows = [
            <div style={{ width: 150 }}>
                <div className="imageWindow arrows arrow-left" 
                onClick={() => {this.hideAll(); this.slider.slickPrev();}}>
                    <img className="navImage" src={leftArrow} alt="previous" style={{ height: "2em" }}/>
                </div>
            </div>,
            <div style={{ width: 150 }}>
                <div className="imageWindow arrows arrow-right" onClick={() => {this.hideAll(); this.slider.slickNext();}} >
                    <img className="navImage" src={rightArrow} alt="next"  style={{ height: "2em" }}/>
                </div>
            </div>
        ]
        let navArrowsDark = [
            <div style={{ width: 150 }}>
                <div className="imageWindow arrows arrow-left" onClick={() => {this.hideAll(); this.slider.slickPrev();}}>
                    <img className="navImage" src={darkLeftArrow} alt="previous" style={{ height: "2em" }}/>
                </div>
            </div>,
            <div style={{ width: 150 }}>
                <div className="imageWindow arrows arrow-right" onClick={() => {this.hideAll(); this.slider.slickNext();}} >
                    <img className="navImage" src={darkRightArrow} alt="next"  style={{ height: "2em" }}/>
                </div>
            </div>
        ]

        return (
            <div>
            <div className={(this.props.data.darkTheme ? "alpha darkTheme slider-wrapper " : "alpha slider-wrapper ") + (this.props.data.thumbnails ? "thumbnails" : "no-thumbnails")} >
                <div>
                    <Slider ref={slider => (this.slider = slider)} {...mainSettings}>
                    {slides}
                    </Slider>
                    <div className={`slider-control ${!this.props.data.thumbnails && "slider-control-arrows"}`}>
                        <div className="slider-control__left">
                            <button onClick={() => {this.toggleSliderButton(true)}} ref={this.sliderButton} style={{ backgroundColor: this.props.data.slideControlColor != '' ? this.props.data.slideControlColor : this.props.theme.colors.accent1 }} 
                            className={`slider-control__btn ${true ? '' : "black"}`}>
                            </button>
                            <div className="slider-control__progress">
                                <div ref={this.progressBar} className="slider-control__progress-value" style={{ backgroundColor: this.props.data.slideControlColor != '' ? this.props.data.slideControlColor : this.props.theme.colors.accent1 }}></div>
                            </div>
                        </div>
                    </div>
                    <div className="slide-number">
                        <span>{this.state.currentSlide}</span>/<span>{this.state.totalSlides}</span>
                    </div>
                    <div className={`sliderTwo slide-nav-wrap ${!this.props.data.thumbnails && "arrows-wrap"}`}>
                        <style>{`
                            .alpha .slick-dots li.slick-active button:before{
                                background-color: ${this.props.data.slideControlColor != '' ? this.props.data.slideControlColor : this.props.theme.colors.accent1}
                            }
                        `}</style>
                        <Slider ref={slider => (this.nav = slider)} {...navSettings}>
                            {this.props.data.thumbnails ? navSlides : (this.props.data.darkTheme ? navArrowsDark :navArrows)}
                        </Slider>
                    </div>
                </div>
                
                { (this.state.alphaslider[this.state.currentSlide - 1].disclaimerText != '<p dir = "auto"></p>\n' && this.state.alphaslider[this.state.currentSlide - 1].disclaimerText != '' && this.state.alphaslider[this.state.currentSlide - 1].disclaimerButton != '') ?
                <button id="slider-disclaimer" className="slider-disclaimer" ref={this.disclaimerButton} onClick={() => { this.toggleDisclaimer(true); this.toggleSliderButton(!(this.state.paused));}}
                    style={{color: this.state.alphaslider[this.state.currentSlide - 1].disclaimerButtonTextColor, backgroundColor: this.rgbaTranslator(this.state.alphaslider[this.state.currentSlide - 1].disclaimerBlockColor, "0.7")}}
                    onMouseOver={() => {
                        document.getElementById("slider-disclaimer").style.backgroundColor = this.rgbaTranslator(this.state.alphaslider[this.state.currentSlide - 1].disclaimerBlockColor, "0.7");
                    }}
                    onMouseOut={() => {
                        document.getElementById("slider-disclaimer").style.backgroundColor = this.rgbaTranslator(this.state.alphaslider[this.state.currentSlide - 1].disclaimerBlockColor, "0.7");
                    }}
                    onFocus
                    onBlur>
                    <span className="disclaimer-toggle-arrow"></span>{this.state.alphaslider[this.state.currentSlide - 1].disclaimerButton}
                </button> :
                <button id="slider-disclaimer" className="slider-disclaimer" ref={this.disclaimerButton} onClick={() => { this.toggleDisclaimer(true); this.toggleSliderButton(!(this.state.paused));}}
                style={{display: "none"}}
                onMouseOver={() => {
                    document.getElementById("slider-disclaimer").style.backgroundColor = this.rgbaTranslator(this.state.alphaslider[this.state.currentSlide - 1].disclaimerBlockColor, "0.7");
                }}
                onMouseOut={() => {
                    document.getElementById("slider-disclaimer").style.backgroundColor = this.rgbaTranslator(this.state.alphaslider[this.state.currentSlide - 1].disclaimerBlockColor, "0.7");
                }}
                onFocus
                onBlur>
                <span className="disclaimer-toggle-arrow"></span>{this.state.alphaslider[this.state.currentSlide - 1].disclaimerButton}
            </button>
                }
                { 
                    <div id="disclaimer-placement" ref={this.disclaimerBox} className="disclaimer-placement"
                    style={{backgroundColor: this.rgbaTranslator(this.state.alphaslider[this.state.currentSlide - 1].disclaimerBlockColor, "0.7")}}>            
                        <p style={{color: this.state.alphaslider[this.state.currentSlide - 1].disclaimerBlockTextColor}} dangerouslySetInnerHTML={{ __html:  this.state.alphaslider[this.state.currentSlide - 1].disclaimerText}}></p>
                    </div>
                }
                
            </div>
                { (this.state.alphaslider[this.state.currentSlide - 1].disclaimerText != '<p dir = "auto"></p>\n' && this.state.alphaslider[this.state.currentSlide - 1].disclaimerText != '' && this.state.alphaslider[this.state.currentSlide - 1].disclaimerButton != '') ?
                <button id="mobile-slider-disclaimer" className="mobile-slider-disclaimer" 
                ref={this.disclaimerButton2}
                onClick={() => {this.toggleMobileDisclaimer(true); this.toggleSliderButton(!(this.state.paused));}}><span className="disclaimer-toggle-arrow"></span>{this.state.alphaslider[this.state.currentSlide - 1].disclaimerButton}
                </button> :
                    <button style={{display: "none"}} id="mobile-slider-disclaimer" className="mobile-slider-disclaimer" 
                    ref={this.disclaimerButton2}
                    onClick={() => {this.toggleMobileDisclaimer(true); this.toggleSliderButton(!(this.state.paused));}}><span className="disclaimer-toggle-arrow"></span>{this.state.alphaslider[this.state.currentSlide - 1].disclaimerButton}
                    </button>
            }

            {
                    <div id="mobile-disclaimer-placement" ref={this.mobileDisclaimerBox} className="mobile-disclaimer-placement"
                    //style={{backgroundColor: "var(--primary-text)"}}
                    >            
                        <p style={{color: this.state.alphaslider[this.state.currentSlide - 1].disclaimerTextColor}} dangerouslySetInnerHTML={{ __html: this.state.alphaslider[this.state.currentSlide - 1].disclaimerText}}></p>
                    </div>
                }
        </div>
        )
    }
}

AlphaSlider.propTypes = {
    data: PropTypes.object,
    theme: PropTypes.object
}

export default AlphaSlider